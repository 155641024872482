import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import { styled } from 'linaria/react';
import React, { useMemo } from 'react';
import { theme } from '../Theme';
import { PreStyledPrice } from '../ui/PreStyledPrice';
import { CartItemCard } from './CartItemCard';
import cartQuery from './CartQuery.gql';
import CartUsp from './CartUsp';
import FreeShipping from './FreeShipping';
import { useVoyadoTracker } from '../Voyado/useVoyadoTracker';
import useHeaderHeight from '../../hooks/useHeaderHeight';

const DrawerWrapper = styled('div')`
  // Flyout
  > div {
    background: #f3f3f3;
    top: ${props => props.topPosition}px;
  }
`;

const Flyout = styled('div')`
  background: #f3f3f3;
  color: ${theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;

  &.empty {
    background: #fff;
  }

  .cart-upper {
    width: 100%;
    background: white;
  }

  .items-wrapper {
    width: 100%;
  }
  > section {
    ${theme.below.sm} {
      position: absolute;
      bottom: 80px;
      left: 0;
      right: 0;
    }
  }

  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    top: 0;
    width: 22rem;
    min-width: 19rem;
    max-width: 100%;
    display: block;
  }

  &.cart {
    ${theme.below.lg} {
      max-height: 70%;
    }
  }
`;

const CartItems = styled('section')`
  a {
    color: black;
    text-decoration: none;
    line-height: 1.2;
  }
`;

const Summary = styled('section')`
  padding: 1rem;
  background: #f3f3f3;
  flex: 1;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 1.2em;
  }

  .discounts {
    margin-bottom: 1em;
    h2 {
      margin-bottom: 1em;
    }
  }

  .cart-total {
    .shipping,
    .total,
    .vat {
      display: flex;
      justify-content: center;
      align-items: center;
      > * {
        padding: 0 3px;
      }
    }
    .vat {
      font-size: 14px;
      margin-top: 3px;
    }

    .total {
      font-weight: bold;
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.05em;
      color: ${theme.colors.black};
    }
  }
  .CartUsp {
    > div {
      padding: 0;
    }
    img {
      height: 20px !important;
    }
  }
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Checkout = styled('a')`
  width: 100%;
  display: flex;
  color: white !important;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  text-decoration: none;
  font-weight: normal;
  margin-top: 1rem;
  background: ${theme.colors.gold};
  font-family: ${theme.fonts.primary};
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.05em;

  &:hover,
  &:active {
    background: #9e7d47;
    text-decoration: none !important;
  }
`;

const ContinueShoppingButton = styled(Checkout)`
  background: #000;
  margin-top: 10px;
  &:hover,
  &:active {
    background: #333;
    text-decoration: none !important;
    cursor: pointer;
  }
`;

const StyledPrice = styled(PreStyledPrice)`
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row-reverse;

  ${Price.Old} {
    color: #848484;
    text-decoration: none;
    margin-right: 5px;
  }
  ${Price.New} {
    color: ${theme.colors.gold};
  }
  ${Price.Normal} {
    color: ${theme.colors.black};
  }
`;

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: ${props => props.topPosition}px;
  height: calc(100vh - ${props => props.topPosition}px);
  z-index: 99999;
  max-width: 100%;

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = result.data.cart.items ?? [];
  const checkoutUrl = result.data.cart.externalCheckoutUrl ?? null;
  const discounts = result.data.cart.aggregatedDiscounts ?? [];
  const track = useTracker();

  useVoyadoTracker();

  if (items.length === 0) {
    return (
      <Flyout {...rest} className="empty">
        <div style={{ padding: '2em' }}>{t('No items in cart.')}</div>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest} className="cart">
      <div className="cart-upper">
        <div className="items-wrapper">
          <CartItems>
            {items.map(item => (
              <CartItemCard item={item} key={item.id} />
            ))}
          </CartItems>
        </div>
      </div>
      <Summary>
        {discounts.length > 0 && (
          <div className="discounts">
            <h2>{t('Cart total')}</h2>
            <Flex>
              <label>{t('Order value')}</label>
              <StyledPrice price={result.data.cart.productPreviousTotal} />
            </Flex>
            <h2 style={{ marginTop: '1em' }}>{t('Discounts')}</h2>
            {discounts.map(discount => {
              return (
                <Flex key={discount.name} style={{ marginTop: '1em' }}>
                  <label>{discount.name}</label>
                  <StyledPrice
                    price={discount.value}
                    style={{ display: 'inline' }}
                  />
                </Flex>
              );
            })}
          </div>
        )}

        <div className="cart-total">
          <FreeShipping
            className="shipping"
            cartTotal={result.data.cart.productTotal}
          />

          <div className="total">
            <label>{t('Total')}:</label>
            <StyledPrice price={result.data.cart.productTotal} />
          </div>

          {checkoutUrl && (
            <Checkout
              href={checkoutUrl}
              onClick={() => {
                track(trackCartCheckoutEvent({ cart: result.data.cart }));
              }}
            >
              {t('Check out')}
            </Checkout>
          )}

          <ContinueShoppingButton onClick={modal.hideTarget}>
            {t('Continue shopping')}
          </ContinueShoppingButton>
        </div>
        <CartUsp />
      </Summary>
    </Flyout>
  );
};

export const PositionedDrawer = ({
  isOpen,
  size = 320,
  children,
  belowHeader = true,
  left
}) => {
  const topPosition = useMemo(() => {
    let topPos = 0;
    if (!belowHeader) return topPos;

    if (typeof document !== 'undefined') {
      const topBarHeight = document.getElementById('topBar')?.clientHeight ?? 0;
      topPos += document.getElementById('stickyHeader')?.clientHeight ?? 0;
      topPos += topBarHeight;
      if (window.pageYOffset < topBarHeight) {
        topPos -= window.pageYOffset;
      } else if (window.pageYOffset > topBarHeight) {
        topPos -= topBarHeight;
      }
    }
    return topPos;
  }, [belowHeader]);

  return (
    <NewDrawer
      isOpen={isOpen}
      size={size}
      topPosition={topPosition}
      className={left ? 'left' : ''}
    >
      {children}
    </NewDrawer>
  );
};

const CartFlyout = props => {
  const topPosition = useHeaderHeight();
  return (
    <CartProvider query={cartQuery}>
      {result =>
        result.data && result.data.cart ? (
          <DrawerTarget id="cart-drawer">
            {drawer => (
              <DrawerWrapper topPosition={topPosition}>
                <Drawer isOpen={drawer.isOpen} right>
                  <CartFlyoutView modal={drawer} result={result} {...props} />
                </Drawer>
              </DrawerWrapper>
            )}
          </DrawerTarget>
        ) : null
      }
    </CartProvider>
  );
};

export default CartFlyout;
