import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import CategoryContentQuery from '../ContentEditor/CategoryContentQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { cartUspComponents } from '../ContentEditor/ContentEditorComponents';

const CartUsp = () => {
  const categoryResult = useQuery(CategoryContentQuery, {
    variables: {
      id: 171
    }
  });

  return (
    <div className="CartUsp">
      {categoryResult?.data?.category?.data?.items && (
        <ContentRenderer
          items={categoryResult?.data?.category?.data?.items}
          components={cartUspComponents}
        />
      )}
    </div>
  );
};

export default React.memo(CartUsp);
