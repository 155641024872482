import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import ProductLink from '@jetshop/ui/ProductLink';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';
import { PreStyledPrice } from '../ui/PreStyledPrice';
import cartQuery from './CartQuery.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import removeFromCartMutation from './removeFromCart.gql';

const ItemWrapper = styled('div')`
  position: relative;
  display: flex;
  height: 128px;
  padding: 24px 24px 0 24px;
  width: 100%;

  a {
    text-decoration: none;
    width: 100%;
    &:hover {
      text-decoration: none !important;
    }
  }

  &:last-of-type {
    > div {
      border-bottom: 0;
    }
  }
`;

const CardContent = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  padding-bottom: 24px;
  border-bottom: 1px solid #dfdfdf;

  > div:first-child {
    background: rgba(0, 0, 0, 0.04);
    position: relative;
    width: 33.333%;
    overflow: hidden;
  }
`;

const CardImage = styled(Image)`
  [data-flight-image] {
    mix-blend-mode: multiply;
  }
`;

const CardInfo = styled('div')`
  flex: 1;
  max-width: 66.66%;
  position: relative;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3,
  ul li {
    font-family: ${theme.fonts.primary};
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: black;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TopRow = styled('div')`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
`;

const BottomRow = styled('div')`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const RemoveItem = styled('div')`
  margin-left: 5px;
  button {
    outline: none;
    border: none;
    background: #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    display: inline-block;
    line-height: 24px;
    font-size: 13px;
  }
  svg {
    height: 10px;
    width: 10px;
  }
`;

const NameAndVariantWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 28px);
`;

const AdjustQty = styled('div')`
  margin-top: 0.5rem;
  display: flex;
  /* Plus/minus buttons */
  button {
    background: #fff;
    color: black;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    :focus {
      outline: none;
    }
  }

  button:first-child {
    padding-bottom: 3px;
    margin-left: -10px;
  }
  /* Count */
  span {
    margin: 0 3px;
    display: inline-block;
    line-height: 24px;
    font-size: 11px;
    border: 1px solid black;
    padding: 0 10px;
  }
`;

const LinePrice = styled(PreStyledPrice)`
  margin-top: 0.5rem;
  font-weight: 600;
  text-align: right;
  align-items: center;
  display: flex;
  font-family: ${theme.fonts.primary};
  font-style: normal;
  color: ${theme.colors.darkgrey};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.05em;

  .new-price {
    color: ${theme.colors.gold};
    margin-right: 4px;
  }
`;

export const CartItemCard = ({ item }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  // const variantNamesValues = variantValues.map(
  //   (value, index) => `${item.variantOptionNames[index]}: ${value}`
  // );
  const variantNamesValues = variantValues.map(value => value);

  return (
    <ItemWrapper>
      <FlyoutTrigger id="cart-flyout">
        {flyout => (
          <CardContent>
            <div>
              {item.product.images.length > 0 && (
                <CardImage
                  aspect="1:1"
                  sizes="5rem"
                  crop
                  src={
                    hasVariantImage
                      ? variantImage.url
                      : item.product.images[0].url
                  }
                  alt={
                    hasVariantImage
                      ? variantImage.alt
                      : item.product.images[0].alt
                  }
                />
              )}
            </div>
            <CardInfo>
              <TopRow>
                <NameAndVariantWrapper>
                  <ProductLink
                    product={item?.product}
                    onClick={flyout.hideTarget}
                  >
                    <h3>
                      <strong>{item?.subHeading}</strong>
                    </h3>
                    <h3>{item?.product?.name}</h3>

                    {isVariant && (
                      <ul
                        style={{
                          marginTop: '0'
                        }}
                      >
                        {variantNamesValues.map(
                          (variantOptionNameValue, index) => (
                            <li className="attribute" key={index}>
                              {t(`${item?.variantOptionNames}`)}
                              {variantOptionNameValue}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                    {item.configurations.length > 0 && (
                      <ul
                        style={{
                          marginTop: '0'
                        }}
                      >
                        {item.configurations.map(
                          ({ option: { name } }, index) => (
                            <li className="attribute" key={index}>
                              {name} - name
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </ProductLink>
                </NameAndVariantWrapper>
                <RemoveItem>
                  <button
                    onClick={() =>
                      removeFromCart({ itemId: item.id, product: item.product })
                    }
                    data-testid="remove-from-cart"
                  >
                    <Cross />
                  </button>
                </RemoveItem>
              </TopRow>
              <BottomRow>
                <AdjustQty>
                  <button
                    data-testid="decrement-quantity"
                    disabled={item.quantity === 1}
                    onClick={() =>
                      item.quantity !== 1 &&
                      decrementQuantity({ itemId: item.id })
                    }
                  >
                    -
                  </button>
                  <span data-testid="item-quantity">{item.quantity}</span>
                  <button
                    data-testid="increment-quantity"
                    onClick={() => incrementQuantity({ itemId: item.id })}
                  >
                    +
                  </button>
                </AdjustQty>
                <LinePrice
                  data-testid="item-price"
                  price={item.total}
                  previousPrice={item.previousTotal}
                />
              </BottomRow>
            </CardInfo>
          </CardContent>
        )}
      </FlyoutTrigger>
    </ItemWrapper>
  );
};
